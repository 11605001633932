import { get } from 'lodash'
import ArticleBox from '~/themes/base/components/content/ArticleBox'

export default {
  extends: ArticleBox,
  props: {
    withoutImageAndPerex: null,
  },
  methods: {
    getMasterCategoryClass(category) {
      const id = get(category, 'master_category[0].entity._id') || get(category, '_id')
      const name = id ? get(this.$store, 'state.globals.settings.content.masterCategories[' + id + ']') : false
      return name ? this.$themeSettings.components.PageArticleDetail.category.classPrefix + name : false
    },
  },
}
