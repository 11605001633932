<script>
import { get } from 'lodash'

export default {
  computed: {
    getHeaderData() {
      return {
        description: !this.isSearch ? get(this.headerConfiguration, 'description') || false : false,
        image: {
          alt: get(this.headerConfiguration, 'image.[0].alt') || '',
          path: get(this.headerConfiguration, 'image[0].path') || '',
        },
        title: get(this.headerConfiguration, 'title') || '',
        perex: get(this.headerConfiguration, 'perex') || '',
      }
    },
    showHeaderImage() {
      const imageRequired = !!get(this.$themeSettings, 'components.ContentHeader.image.required')
      return imageRequired === false || (imageRequired && this.getHeaderData.image.path)
    },
  },
}
</script>
